import styled, { css } from "styled-components";
import {
  fadeIn,
  slideInFromLeft,
  slideInFromRight,
} from "../Animations/animations";
import { Link } from "react-router-dom";

export const HeroWrapper = styled.section`
  position: relative;
  background-image: url(https://lunexdirect.solar/hero.webp);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 64em) {
    //1024px
    background-attachment: scroll;
  }

  @media (max-width: 37.5em) {
    min-height: 80vh;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 10;
  }
`;

export const HeroLogo = styled.img`
  max-width: 52rem;
  max-width: 41.6rem;
  aspect-ratio: 3.6497;
  padding-top: 1.2rem;
  z-index: 11;
  animation: ${fadeIn} 1.5s ease forwards;

  @media (max-width: 28.125em) {
    //450px
    max-width: 90vw;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-top: 10rem;
  padding: 1.2rem 0 4.8rem 0;
  z-index: 12;
  width: 100%;

  @media (max-width: 59.5em) {
    //952px
    margin-top: 12rem;
  }
`;

export const TitleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 2rem 1rem;
  flex-wrap: wrap;

  @media (max-width: 78.5625em) {
    //1257px
    margin-bottom: 4rem;
    flex-direction: column;
  }
`;

export const SectionTitle = styled.h1`
  font-size: 3.3rem;
  font-weight: 600;
  text-shadow: 2px 2px 10px black;
  color: white;
  text-align: center;
  text-transform: uppercase;
  animation: ${fadeIn} 1.5s ease forwards;

  &:first-child {
    transform: translateX(1.2rem);
  }

  &:last-child {
    transform: translateX(-1.2rem);
  }

  @media (max-width: 78.5625em) {
    //1257px
    &:first-child {
      transform: translate(0, 1.8rem);
    }

    &:last-child {
      transform: translate(0, -0.8rem);
    }
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8rem;
  height: auto;
  padding: 0 4rem;

  @media (max-width: 62.5em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: unset;
    padding: unset;
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 46.875em) {
    //750px
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const AppButtonLink = styled.a`
  &:link,
  &:visited {
    background-color: #1ba8f1;
    color: white;
    font-size: 2rem;
    font-size: 1.6rem;
    text-decoration: none;
    display: inline-block;
    padding: 2.2rem 4.4rem;
    padding: 1.76rem 3.52rem;
    border-radius: 10px;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    opacity: 0;

    transform: translateX(100px);
    ${({ $visible }) =>
      $visible &&
      css`
        animation: ${slideInFromRight} 1s ease forwards;
      `}

    @media (max-width: 40.625em) {
      padding: 1.6rem 3.2rem;
    }

    @media (max-width: 31.25em) {
      font-size: 1.6rem;
    }
  }

  &:hover,
  &:active {
    background-color: #1897d9;
  }
`;

export const GuideButtonLink = styled(Link)`
  &:link,
  &:visited {
    background-color: white;
    background-color: none;
    color: #101522;
    font-size: 2rem;
    font-size: 1.6rem;
    text-decoration: none;
    display: inline-block;
    padding: 2.2rem 4.4rem;
    padding: 1.76rem 3.52rem;
    border-radius: 10px;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    opacity: 0;
    transform: translateX(-100px);
    ${({ $visible }) =>
      $visible &&
      css`
        animation: ${slideInFromLeft} 1s ease forwards;
      `}

    @media (max-width: 40.625em) {
      padding: 1.6rem 3.2rem;
    }

    @media (max-width: 31.25em) {
      font-size: 1.6rem;
    }
  }

  &:hover,
  &:active {
    background-color: #e6e6e6;
  }
`;
