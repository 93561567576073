import styled, { css } from "styled-components";
import { fadeIn, grow } from "../Animations/animations";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  background-color: rgb(16, 21, 34);
  border-radius: 10px;
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease forwards;
  ${({ $visible }) => $visible && "box-shadow: 2px 2px 14px black"};
  ${({ $visible }) =>
    $visible ? "box-shadow: 2px 2px 14px black" : "box-shadow: unset"};

  @media (max-width: 71.875em) {
    //1,150px
    width: 50vw;
  }

  @media (max-width: 54.375em) {
    //870px
    width: 60vw;
  }

  @media (max-width: 41.875em) {
    //670px
    width: 75vw;
  }
`;

export const CardImage = styled.img`
  width: 40vw;
  aspect-ratio: 2.1316;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 1rem;

  @media (max-width: 71.875em) {
    //1,150px
    width: 50vw;
  }

  @media (max-width: 54.375em) {
    //870px
    width: 60vw;
  }

  @media (max-width: 41.875em) {
    //670px
    width: 75vw;
  }
`;

export const CardTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover,
  :active {
    box-shadow: unset;
  }
`;

export const CardTitle = styled.h2`
  font-size: 3.2rem;
  font-weight: 500;
  color: #101522;
  color: white;
  text-align: center;
  margin: 0 2rem;
  text-transform: uppercase;
  transform: scale(-1);
  animation: ${grow} 1s ease forwards;
`;

export const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem 2rem 2rem;
  gap: 2rem;
`;

export const CardText = styled.p`
  font-size: 2rem;
  color: white;
  width: 100%;
  text-align: center;
  transform: scale(0);
  ${({ $elNum }) =>
    $elNum === 1
      ? css`
          animation: ${grow} 1s ease forwards 1s;
        `
      : $elNum === 2
      ? css`
          animation: ${grow} 1s ease forwards 2s;
        `
      : $elNum === 3
      ? css`
          animation: ${grow} 1s ease forwards 3s;
        `
      : null}
`;
