import { useState, useEffect, useMemo, useRef } from "react";
import HeroSection from "../HeroSection"; // Existing NewHeroSection component
import AnimationCard from "../AnimationCard";
import { useAnimationContext } from "../../globalState/heroAnimationHook";
import {
  TranslationWrapper,
  BlinkingCursor,
  Message,
  HeroWrapper,
  SkipButton,
} from "./HeroAnimationElements";
import { Card1Obj, Card2Obj, Card3Obj } from "../HeroCard/data"; // Data objects for cards

const HeroAnimationSequence = () => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0); // Tracks the animation step
  const { isAnimating, setIsAnimating } = useAnimationContext(); // Access global state that determines when to show NewHeroSection
  const [typedText, setTypedText] = useState(""); // Text that is progressively typed

  const cards = useMemo(() => [Card2Obj, Card3Obj, Card1Obj], []);
  const messages = useMemo(
    () => [
      "Tell me more about the NEW Lunex Direct Solar Design Generator...",
      "AI generated design??? That's awesome! How long does it take to get pricing though?",
      "Wow! So where does the salesman fit into all this then???",
    ],
    []
  );
  const typingSpeed = 60; // speed of typing in milliseconds
  const charIndexRef = useRef(0); // Tracks the current character index

  useEffect(() => {
    const preloadAssets = async () => {
      const images = cards.map((card) => {
        const img = new Image();
        img.src = card.imgSrc;
        return new Promise((resolve) => {
          img.onload = resolve;
        });
      });

      // Wait for all images to preload
      await Promise.all(images);
      setLoading(false); // indicate preloading is complete
    };

    preloadAssets();
  }, [cards]);

  useEffect(() => {
    if (step % 2 === 0) {
      const currentMessage = messages[Math.floor(step / 2)];
      setTypedText(""); // reset text
      charIndexRef.current = 0; // reset index
      let tempText = ""; // Temporary variable to build text
      let isPaused = false; // Track if a pause is ongoing

      const typeTimer = setInterval(() => {
        if (
          currentMessage != undefined &&
          !isPaused &&
          charIndexRef.current < currentMessage.length
        ) {
          const currentChar = currentMessage[charIndexRef.current];
          tempText += currentChar;
          setTypedText(tempText);
          charIndexRef.current++;

          // Detect end of sentence
          if (
            [".", "?", "!"].includes(currentChar) &&
            currentMessage[charIndexRef.current] === " "
          ) {
            isPaused = true; // Start a pause
            setTimeout(() => {
              // Resume typing after .5s
              isPaused = false;
            }, 500);
          }
        } else if (
          currentMessage != undefined &&
          charIndexRef.current >= currentMessage.length
        ) {
          clearInterval(typeTimer); // stop typing when done
        }
      }, typingSpeed);
      return () => clearInterval(typeTimer); // cleanup effect on rerun
    }
  }, [step, messages, typingSpeed]);

  useEffect(() => {
    // Message Timer
    if (step < cards.length * 2 && step % 2 === 0) {
      const currentMessage = messages[Math.floor(step / 2)];

      // Count instances of ". ", "! ", or "? "
      const pauseCount = (currentMessage.match(/([.?!])(?= )/g) || []).length;
      const totalPauseTime = pauseCount * 500; // 500ms per punctuation pause

      const messageLength = currentMessage.length;
      const messageTimer = setTimeout(() => {
        setStep((prev) => prev + 1);
      }, typingSpeed * messageLength + totalPauseTime + 1000); // Adjust timing for message display
      return () => clearTimeout(messageTimer);
      // Card Timer
    } else if (step < cards.length * 2 && step % 2 === 1) {
      const cardTimer = setTimeout(() => {
        setStep((prev) => prev + 1);
      }, 5000); // Adjust timing for card display
      return () => clearTimeout(cardTimer);
    } else {
      setIsAnimating(false); // Transition to NewHeroSection after animations
    }
  }, [step, cards.length, setIsAnimating, messages]);

  const handleSkip = () => {
    setIsAnimating(false);
  };

  if (loading) {
    return (
      <HeroWrapper>
        <BlinkingCursor>Loading...</BlinkingCursor>
      </HeroWrapper>
    );
  }

  // if (!isAnimating) {
  //   return <HeroSection />;
  // }

  const currentCardIndex = Math.floor(step / 2); // Determine which card/message to display
  const isCardVisible = step % 2 === 1; // Alternate between showing message and card

  return (
    <>
      {isAnimating ? (
        <HeroWrapper>
          <SkipButton onClick={handleSkip}>Skip</SkipButton>
          <TranslationWrapper>
            {/* Display typewriting text with blinking cursor */}
            {step % 2 === 0 ? (
              <Message>
                {typedText}
                <BlinkingCursor />
              </Message>
            ) : null}

            {/* Display fading card */}
            {step % 2 === 1 ? (
              <AnimationCard
                {...cards[currentCardIndex]}
                isVisible={isCardVisible}
                cardNum={currentCardIndex + 1}
              />
            ) : null}
          </TranslationWrapper>
        </HeroWrapper>
      ) : (
        <HeroSection />
      )}
    </>
  );
};

export default HeroAnimationSequence;
