import NavBar from "../NavBar";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import MobileNavBar from "../MobileNavBar";
import { useAnimationContext } from "../../globalState/heroAnimationHook";
import { MobileContext } from "../../globalState/mobileContext";
import { useLocation } from "react-router-dom";

const Layout = function ({ children }) {
  // State to track the screen width
  const [isMobile, setIsMobile] = useState(false);
  const { isAnimating } = useAnimationContext();
  const location = useLocation();

  // Effect hook to listen for window resize events
  useEffect(() => {
    const handleResize = () => {
      // Check if screen width is less than 1000px to determent mobile layout
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the correct layout on page load
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>
      {location.pathname === "/" ? (
        !isAnimating && isMobile ? (
          <MobileNavBar />
        ) : !isAnimating && !isMobile ? (
          <NavBar />
        ) : null
      ) : isMobile ? (
        <MobileNavBar />
      ) : (
        <NavBar />
      )}

      {/* {isMobile ? <MobileNavBar /> : <NavBar />} */}
      {children}
      {location.pathname === "/" && isAnimating ? null : <Footer />}
    </MobileContext.Provider>
  );
};

export default Layout;
