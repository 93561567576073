import { lazy, Suspense } from "react";
import Layout from "../src/components/Layout";
// import HeroSection from "../src/components/HeroSection";
import HeroAnimationSequence from "../src/components/HeroAnimationSequence";
import WhyLunex from "../src/components/WhyLunex";
const CustomerMap = lazy(() => import("../src/components/CustomerMap"));
const LunexDifference = lazy(() => import("../src/components/LunexDifference"));
const InfoSection = lazy(() => import("../src/components/InfoSection"));
const PartnerLogos = lazy(() => import("../src/components/PartnerLogos"));
// import AnimationCard from "../src/components/AnimationCard";

import {
  InfoSectionObj1,
  InfoSectionObj2,
  InfoSectionObj3,
} from "../src/components/InfoSection/data";
import { useAnimationContext } from "../src/globalState/heroAnimationHook";
// import { Card1Obj, Card2Obj, Card3Obj } from "../src/components/HeroCard/data";

function Home() {
  const { isAnimating } = useAnimationContext();

  return (
    <>
      <Layout>
        {/* <HeroSection /> */}
        <HeroAnimationSequence />
        {/* <AnimationCard {...Card2Obj} /> */}
        {!isAnimating ? (
          <>
            <WhyLunex />
            <Suspense fallback={<div>Loading...</div>}>
              <LunexDifference />
              <InfoSection {...InfoSectionObj1} />
              <PartnerLogos />
              <InfoSection {...InfoSectionObj2} />
              <InfoSection {...InfoSectionObj3} />
              <CustomerMap />
            </Suspense>
          </>
        ) : null}
      </Layout>
    </>
  );
}

export default Home;
