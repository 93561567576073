import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { useCallback, useState, useEffect, useRef } from "react";
import {
  NBar,
  NBarLeftWrapper,
  NavLogo,
  NBarMiddleWrapper,
  NavPageLink,
  NavLink,
  NBarRightWrapper,
  NavButtonLink,
  NBarWrapper,
} from "./NavBarElements";
import lunexDirectLogo from "../../../public/lunex-DIRECT-color-transparent-web.svg";

const NavBar = function () {
  const location = useLocation();

  const [hidden, setHidden] = useState(false);
  let lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop.current) {
      setHidden(true);
    } else {
      setHidden(false);
    }

    lastScrollTop.current = scrollTop;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <NBarWrapper>
      <NBar hidden={hidden}>
        <NBarLeftWrapper>
          <Link to="/">
            <NavLogo src={lunexDirectLogo} alt="Lunex logo" />
          </Link>
        </NBarLeftWrapper>
        <NBarMiddleWrapper>
          <NavPageLink to="/">Home</NavPageLink>
          <NavPageLink to="/faq">FAQ</NavPageLink>
          <NavPageLink to="/incentives">Incentives</NavPageLink>
          <NavPageLink to="/guide">Guide</NavPageLink>
        </NBarMiddleWrapper>
        {location.pathname === "/" ? (
          <NBarRightWrapper>
            <NavLink href="https://www.facebook.com/Lunexpowerinc/">
              <FaFacebookF />
            </NavLink>
            <NavLink href="https://www.instagram.com/lunexpowerinc/?hl=en">
              <FaInstagram />
            </NavLink>
            <NavLink href="https://www.linkedin.com/company/lunex-power-inc/">
              <FaLinkedinIn />
            </NavLink>
          </NBarRightWrapper>
        ) : (
          <NBarRightWrapper>
            <NavButtonLink href={import.meta.env.VITE_APP_URL}>
              GENERATE QUOTE
            </NavButtonLink>
          </NBarRightWrapper>
        )}
      </NBar>
    </NBarWrapper>
  );
};

export default NavBar;
