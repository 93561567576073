import pushySalesmanImg from "../../../public/pushySalesman.webp";
import solarHouseAerialView from "../../../public/SolarHouseAerialView-Cropped.webp";
import proposalImage from "../../../public/ProposalImage-Cropped.webp";

export const Card1Obj = {
  imgSrc: pushySalesmanImg,
  imgAlt: "Pushy door to door salesman",
  cardTitle: "No Salesman",
  textLine1: "Solar made simple",
  textLine2: "Pocket the savings",
  textLine3: "No Pressure",
};

export const Card2Obj = {
  imgSrc: solarHouseAerialView,
  imgAlt: "Solar Design Image",
  cardTitle: "AI Generated Design",
  textLine1: "Custom design",
  textLine2: "AI tools determine optimal panel placement",
  textLine3: "Design ready within minutes",
};

export const Card3Obj = {
  imgSrc: proposalImage,
  imgAlt: "Sample Proposal",
  cardTitle: "Instant Pricing",
  textLine1: "Receive formal quote instantly",
  textLine2: "Everyday low pricing",
  textLine3: "No hassle, no haggle",
};
