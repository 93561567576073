import {
  Card,
  CardImage,
  CardTitleTextWrapper,
  CardTitle,
  CardTextWrapper,
  CardText,
} from "./AnimationCardElements";
// import { HeroWrapper } from "../HeroAnimationSequence";

const AnimationCard = function ({
  imgSrc,
  imgAlt,
  cardTitle,
  textLine1,
  textLine2,
  textLine3,
  isVisible,
  cardNum,
}) {
  return (
    <Card $visible={isVisible} $cardNum={cardNum}>
      <CardImage src={imgSrc} alt={imgAlt} />
      <CardTitleTextWrapper>
        <CardTitle>{cardTitle}</CardTitle>
        <CardTextWrapper>
          <CardText $elNum={1}>{textLine1}</CardText>
          <CardText $elNum={2}>{textLine2}</CardText>
          <CardText $elNum={3}>{textLine3}</CardText>
        </CardTextWrapper>
      </CardTitleTextWrapper>
    </Card>
  );
};

export default AnimationCard;
