import { useState, useRef, useEffect } from "react";
import {
  HeroWrapper,
  HeroLogo,
  CardWrapper,
  SectionWrapper,
  SectionTitle,
  ButtonWrapper,
  AppButtonLink,
  GuideButtonLink,
  TitleLogoWrapper,
} from "./HeroElements";
import HeroCard from "../HeroCard";
import { Card1Obj, Card2Obj, Card3Obj } from "../HeroCard/data";

const NewHeroSection = function () {
  const [isVisible, setIsVisible] = useState({
    card1: false,
    card2: false,
    card3: false,
    appButton: false,
    guideButton: false,
  });

  const cardRefs = useRef([useRef(null), useRef(null), useRef(null)]);
  const appButtonRef = useRef(null);
  const guideButtonRef = useRef(null);

  const observeElement = (ref, key) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible((prev) => ({ ...prev, [key]: true }));
          observer.disconnect();
        }
      },
      { threshold: 0.25 }
    );
    if (ref.current) observer.observe(ref.current);
    return observer;
  };

  useEffect(() => {
    const cardObservers = cardRefs.current.map((ref, index) =>
      observeElement(ref, `card${index + 1}`)
    );
    const appButtonObserver = observeElement(appButtonRef, "appButton");
    const guideButtonObserver = observeElement(guideButtonRef, "guideButton");

    return () => {
      cardObservers.forEach((observer) => observer.disconnect());
      appButtonObserver.disconnect();
      guideButtonObserver.disconnect();
    };
  }, []);

  return (
    <HeroWrapper>
      <SectionWrapper>
        <TitleLogoWrapper>
          <SectionTitle>Check out the NEW</SectionTitle>
          <HeroLogo
            src="lunex-DIRECT-color-transparent-web.svg"
            alt="Lunex logo"
          />
          <SectionTitle>Solar Design Generator!</SectionTitle>
        </TitleLogoWrapper>

        <CardWrapper>
          {[Card1Obj, Card2Obj, Card3Obj].map((cardData, index) => (
            <HeroCard
              key={`Card${index}`}
              ref={cardRefs.current[index]}
              {...cardData}
              isVisible={isVisible[`card${index + 1}`]}
              cardNum={index + 1}
            />
          ))}
        </CardWrapper>
        <ButtonWrapper>
          <GuideButtonLink
            to="/guide"
            ref={guideButtonRef}
            $visible={isVisible.guideButton}
          >
            How to use
          </GuideButtonLink>
          <AppButtonLink
            href={import.meta.env.VITE_APP_URL}
            ref={appButtonRef}
            $visible={isVisible.appButton}
          >
            Generate Quote
          </AppButtonLink>
        </ButtonWrapper>
      </SectionWrapper>
    </HeroWrapper>
  );
};

export default NewHeroSection;
