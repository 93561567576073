import styled from "styled-components";

// Wrapper for the animation sequence
export const HeroWrapper = styled.div`
  position: relative;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  /* overflow: hidden; */
`;

export const SkipButton = styled.button`
  font-family: "AzeretMono", monospace;
  color: black;
  position: absolute;
  /* top: 6rem; */
  bottom: 6rem;
  right: 6rem;
  background: transparent;
  border: none;
  font-size: 2rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const TranslationWrapper = styled.div`
  transform: translateY(-4rem);
`;

// Typewriting text and blinking cursor
export const BlinkingCursor = styled.span`
  font-family: "AzeretMono", monospace;
  font-size: 4rem;
  color: black;
  &::after {
    content: "|";
    animation: blink 1s step-end infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

export const Message = styled.h2`
  font-size: 4rem;
  font-family: "AzeretMono", monospace;
  text-align: center;
  color: black;
  margin: 0 4rem;
  margin: 0 15vw;
  line-height: 1.6;

  @media (max-width: 81.5625em) {
    //1,305px
    margin: 10vw;
  }

  @media (max-width: 46.875em) {
    //750px
    margin: 8vw;
  }
`;
