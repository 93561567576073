import styled, { css } from "styled-components";
import {
  slideInFromLeft,
  slideUp,
  slideInFromRight,
} from "../Animations/animations";

export const CardTransition = styled.div`
  transition: all 0.3s ease;

  @media (max-width: 62.5em) {
    //1000px
    grid-column: ${({ $cardNum }) =>
      $cardNum === 1
        ? "1"
        : $cardNum === 2
        ? "2"
        : $cardNum === 3
        ? "1 / -1"
        : "auto"};
  }

  @media (max-width: 46.875em) {
    //750px
    grid-column: unset;
  }

  /* &:hover {
    transform: scale(1.05);
  } */
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 27.5vw;
  width: 22vw;
  margin-bottom: 4.8rem;
  background-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(16, 21, 34, 0.75);
  /* background-color: rgb(16, 21, 34); */
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: ${({ $cardNum }) =>
    $cardNum === 1
      ? "translateX(-100px) scale(1)"
      : $cardNum === 2
      ? "translateY(100px) scale(1)"
      : $cardNum === 3
      ? "translateX(100px) scale(1)"
      : "none"};

  ${({ $visible, $cardNum }) =>
    $visible &&
    css`
      animation: ${$cardNum === 1
          ? slideInFromLeft
          : $cardNum === 2
          ? slideUp
          : $cardNum === 3
          ? slideInFromRight
          : null}
        1.5s ease forwards;
    `};

  @media (max-width: 62.5em) {
    //1000px
    min-width: 40vw;

    transform: ${({ $cardNum }) =>
      $cardNum === 1
        ? "translateX(-100px) scale(1)"
        : $cardNum === 2
        ? "translateX(100px) scale(1)"
        : $cardNum === 3
        ? "translateY(100px) scale(1)"
        : "none"};

    ${({ $visible, $cardNum }) =>
      $visible &&
      css`
        animation: ${$cardNum === 1
            ? slideInFromLeft
            : $cardNum === 2
            ? slideInFromRight
            : $cardNum === 3
            ? slideUp
            : null}
          1.5s ease forwards;
      `};
  }

  @media (max-width: 46.875em) {
    //750px
    min-width: 60vw;

    transform: ${({ $cardNum }) =>
      $cardNum === 1
        ? "translateX(-100px) scale(1)"
        : $cardNum === 2
        ? "translateX(100px) scale(1)"
        : $cardNum === 3
        ? "translateX(-100px) scale(1)"
        : "none"};

    ${({ $visible, $cardNum }) =>
      $visible &&
      css`
        animation: ${$cardNum === 1
            ? slideInFromLeft
            : $cardNum === 2
            ? slideInFromRight
            : $cardNum === 3
            ? slideInFromLeft
            : null}
          1.5s ease forwards;
      `};
  }
  @media (max-width: 46.875em) {
    //750px
  }

  &:hover,
  :active {
    box-shadow: 1px 1px 16px rgba(255, 255, 255, 0.99);
    background-color: #101522;
  }
`;

export const CardImage = styled.img`
  width: 27.5vw;
  width: 22vw;
  aspect-ratio: 2.1316;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 1rem;

  @media (max-width: 62.5em) {
    //1000px
    min-width: 40vw;
  }

  @media (max-width: 46.875em) {
    //750px
    margin-bottom: 2rem;
  }

  @media (max-width: 46.875em) {
    //750px
    min-width: 60vw;
  }

  &:hover,
  :active {
    box-shadow: unset;
  }
`;

export const CardTitleTextWrapper = styled.div`
  min-height: 19rem;
  min-height: 15.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover,
  :active {
    box-shadow: unset;
  }

  @media (max-width: 93em) {
    //1,488px
    min-height: 20rem;
  }

  @media (max-width: 66.0625em) {
    // 1,057px
    min-height: 22rem;
  }

  @media (max-width: 62.5em) {
    //1000px
    min-height: 15.2rem;
  }

  @media (max-width: 46.875em) {
    //750px
    min-height: unset;
    margin-bottom: 2rem;
  }
`;

export const CardTitle = styled.h2`
  font-size: 3.2rem;
  font-size: 2.56rem;
  font-weight: 500;
  color: #101522;
  color: white;
  text-align: center;
  margin: 0 2rem 0rem 2rem;
  text-transform: uppercase;

  @media (max-width: 46.875em) {
    //750px
    margin-bottom: 2rem;
  }
`;

export const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 2rem auto 2rem;
  padding-bottom: 1rem;
  gap: 2rem;
  gap: 1.6rem;
`;

export const CardText = styled.p`
  font-size: 1.8rem;
  font-size: 1.44rem;
  color: #101522;
  color: white;
  width: 100%;
  align-self: flex-start;
  text-align: center;
`;
