import { useState } from "react";
import { AnimationContext } from "../../globalState/heroAnimationContext";

// Animation Provider component to manage global animation state for hero page
const AnimationProvider = ({ children }) => {
  const [isAnimating, setIsAnimating] = useState(true); // True when animation is running

  const value = {
    isAnimating,
    setIsAnimating,
  };

  return (
    <AnimationContext.Provider value={value}>
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationProvider;
