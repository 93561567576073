import { forwardRef } from "react";
import {
  CardTransition,
  Card,
  CardImage,
  CardTitleTextWrapper,
  CardTitle,
  CardTextWrapper,
  CardText,
} from "./HeroCardElements";
import { useMobileContext } from "../../globalState/mobileHook";

const HeroCard = forwardRef(
  (
    {
      imgSrc,
      imgAlt,
      cardTitle,
      textLine1,
      textLine2,
      textLine3,
      isVisible,
      cardNum,
      animationCard,
    },
    ref
  ) => {
    const isMobile = useMobileContext;

    return (
      <CardTransition $cardNum={cardNum} $animationCard={animationCard}>
        <Card
          ref={ref}
          $visible={isVisible}
          $cardNum={cardNum}
          $animationCard={animationCard}
        >
          <CardImage src={imgSrc} alt={imgAlt} $isMobile={isMobile} />
          <CardTitleTextWrapper>
            <CardTitle>{cardTitle}</CardTitle>
            <CardTextWrapper $isMobile={isMobile}>
              <CardText>{textLine1}</CardText>
              <CardText>{textLine2}</CardText>
              <CardText>{textLine3}</CardText>
            </CardTextWrapper>
          </CardTitleTextWrapper>
        </Card>
      </CardTransition>
    );
  }
);

HeroCard.displayName = "HeroCard";

export default HeroCard;
